import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
// import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "拡散度", content: "中" },
  {
    color: "bg-case_orange",
    title: "抽選方法",
    content: "後日抽選",
  },
  { color: "bg-accent", title: "結果確認方法", content: "DM" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "キャンペーン開催の予算が少ない" },
  { title: "キャンペーン自体をバズらせたい" },
  { title: "自社の認知度をさらに上げたい" },
]

const TwitterFollowAndQuoteTweet = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterキャンペーン事例・ハッシュタグ付き引用リツイート"
          pagepath={location.pathname}
          pagedesc="キャンペーン主催のTwitterアカウントをフォロー・指定のハッシュタグをつけてキャンペーンツイートを引用リツイートすれば応募できるキャンペーンです。拡散性はフォロー＆リツイートキャンペーンより下がるものの、フォロワーとの関係性を構築し、ファンを獲得できます。"
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                フォロー＆
                <br className="block md:hidden" />
                ハッシュタグ引用リツイート
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                開催アカウントをフォロー・指定のハッシュタグをつけて
                <br className="md:block hidden" />
                キャンペーンツイートを引用リツイートすれば応募完了。
                <br className="md:block hidden" />
                拡散性はフォロー＆リツイートキャンペーンより下がるものの、
                <br className="md:block hidden" />
                フォロワーとの関係性を構築し、ファンを獲得できるキャンペーンです。
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <img
                  layout="fullWidth"
                  alt="フォロー＆ハッシュタグ引用リツイート"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/mv_follow_and_quote_tweet.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                こんな方におすすめです
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                キャンペーンの特徴
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      参加者
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">拡散度</p>
                      <StarRates rate={3} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        参加しやすさ
                      </p>
                      <StarRates rate={4} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        モチベーション
                      </p>
                      <StarRates rate={2.5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      ご担当者様
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">手軽さ</p>
                      <StarRates rate={4} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  キャンペーンの内容によって、拡散度が大幅に異なるキャンペーンです。
                  <br />
                  キャンペーン自体が話題になると、大幅にフォロワーを増加させることが出来ます。
                  <br />
                  <br />
                  フォローとハッシュタグ付きの引用リツイートでの参加のため、フォロー&amp;リツイートキャンペーンと比較すると、ユーザーの参加ハードルは高くなります。
                  <br />
                  また、すぐに結果が通知が来るわけではないので、ユーザーの参加のモチベーションは中程度です。
                  <br />
                  <br />
                  キャンペーン中はご担当者様の作業は発生しませんが、キャンペーン終了後に参加者のプロフィールを個別に確認し、DMを送信する必要があるので、ご担当者様の作業量も中程度になります。
                  <br />
                  当選者のみにDMを送信する場合は、キャンペーンツールを使わずとも実施可能です。
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTのキャンペーン
                <br className="block md:hidden" />
                ツールを導入すると
                <br />
                どう変わる？
              </h2>
              <p className="mb-6 text-text">
                PARKLoTのキャンペーンツールを導入することで、大幅に運用時間を削減することができます!
                <br />
                削減できる箇所を、キャンペーンのフローで確認してみましょう。
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    手動でキャンペーンを行なった場合
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      Twitterから該当キャンペーンページを開く
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      参加者一覧表示
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          ユーザーの詳細画面確認
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          当選者決定
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        当選者の数だけ繰り返す
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        当選者アカウントを検索する
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        DMの作成・確認・送信
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        当選者の数だけ繰り返す
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-text mb-3">
                      キャンペーン終了後の作業だけで
                      <br className="block md:hidden" />
                      <span className="font-bold text-2xl text-accent">
                        3〜5時間必要
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        Twitterにログインし、
                        <span className="font-bold">
                          参加者のプロフィールを1件1件確認する
                        </span>
                        必要がある
                      </li>
                      <li>
                        当選者に
                        <span className="font-bold">1件1件DMを送る</span>
                        必要がある
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      PARKLoTを利用した場合
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        PARKLoTで作成したCSVから当選者を決定
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        当選者一覧のCSVを作成
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        PARKLoTが当選者にDM一括送信
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        キャンペーン終了後の作業が
                        <br className="md:hidden block" />
                        <span className="text-2xl text-accent leading-normal">
                          20〜30分で完了！
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          参加者一覧を、
                          <span className="font-bold">
                            ソート、フィルタリングの上CSVで確認
                          </span>
                          できる
                        </li>
                        <li>
                          当選・落選の
                          <span className="font-bold">
                            DMの一括送信をPARKLoTに依頼
                          </span>
                          できる
                        </li>
                        <li>
                          （二次効果として）
                          <span className="font-bold">
                            応募者の傾向を分析、今後のキャンペーンに活かせる
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      以下の場合ツールの導入を
                      <br className="block md:hidden" />
                      おすすめしません
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>応募者の情報が不要</li>
                        <li>当選者数が少ない</li>
                        <li>ツール導入の費用がない</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTを使用した際の
                <br />
                具体的なキャンペーン手順
                <br className="block md:hidden" />
                について
              </h2>
              <p className="mb-6 text-text">
                それではPARKLoTを使用した際のキャンペーン手順を、具体的に説明させて頂きます！
              </p>
              <FlowNumber
                num="01"
                parklot={false}
                diagram={true}
                title="キャンペーン情報をPARKLoTにご提供ください"
              >
                <p className="text-text">
                  キャンペーン開始の３営業日前までに、弊社宛にキャンペーンに関する以下の情報をご提供下さい。
                  <br />
                  弊社にてデータの設定を行います。
                </p>
                <div className="md:w-3/5 bg-white rounded py-4 px-4">
                  <h4 className="font-semibold text-center mb-3">
                    ご提供いただく情報
                  </h4>
                  <ul className="text-xs leading-normal list-disc md:p-4 p-1">
                    <li>主催者のTwitterアカウント名</li>
                    <li>開始日時</li>
                    <li>終了日時</li>
                  </ul>
                </div>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={false}
                title="Twitterにキャンペーンツイートをご投稿ください"
              >
                <p className="text-text">
                  Twitterにキャンペーンのツイートを投稿して下さい。
                </p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="引用リツイートの収集を開始します"
              >
                <p className="text-text">
                  弊社にてキャンペーンツイートを引用リツイートしたユーザの収集を開始します。
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                title="応募者一覧CSVをご担当者様にお送りします"
              >
                <p className="text-text">
                  キャンペーンの参加者一覧をCSVでお渡しします。
                  <br />
                  CSVをチェックの上、当選者の決定をお願い致します。
                </p>
              </FlowNumber>

              <FlowNumber
                num="05"
                parklot={false}
                diagram={true}
                title="当選者を決定し、CSV形式にてPARKLoTにお渡しください"
              >
                <p className="text-text">
                  当選者の決定後、弊社から当選者の方にご連絡させて頂くために必要なCSVを、ご作成頂きます。
                  <br />
                  当選DMの文面も、あわせて共有いただきます。
                  <br />
                  <br />
                  CSVをお送り頂く際に合わせて、DMでお送りする当選文の文面もご連絡下さい。
                </p>
                <div>
                  <div className="bg-white rounded py-4 px-4">
                    <h4 className="font-semibold text-center mb-3">
                      ご提供いただく情報
                    </h4>
                    <ul className="text-xs leading-normal list-disc md:p-4 p-1">
                      <li>ユーザーID</li>
                      <li>ユーザー名</li>
                      <li>アカウント名</li>
                      <li>景品名</li>
                    </ul>
                  </div>
                  <p className="text-text">（例）</p>
                  <img
                    layout="constrained"
                    alt="ご提供いただく情報"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/winners_csv.png"
                  />
                </div>
              </FlowNumber>

              <FlowNumber
                num="06"
                parklot={true}
                diagram={true}
                last={true}
                title="当選者にDMを一括送信します"
              >
                <p className="text-text">
                  ご担当者様からお送り頂いた当選文とCSVを元に、各当選者の方にDMを一括送信致します。
                </p>
                <div>
                  <img
                    layout="constrained"
                    alt="当選者へのDM"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/usage_flow05.jpg"
                  />
                </div>
              </FlowNumber>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTの月額料金に
                <br className="block md:hidden" />
                含まれる機能は？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                一般的なフォロー&amp;ハッシュタグ付き引用リツイートキャンペーンは、月額料金内でご利用頂けます。
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                月額料金内に含まれる機能
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 place-content-center">
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt="インスタントウィンキャンペーン実施費用"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    ハッシュタグ付き引用リツイートの取得
                  </h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt="参加者情報のCSV出力"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    キャンペーン参加者一覧CSVのご提供
                  </h4>
                  <p className="text-text text-xs">
                    *複雑なカスタマイズが必要な場合、
                    <br />
                    別途料金を頂く可能性がございます
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default TwitterFollowAndQuoteTweet
